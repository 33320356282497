import { Component, Provide, Vue, Watch } from 'vue-property-decorator';
import { mapGetters } from "vuex";
import { AddAddressReq } from "@/interface/res/mine";
import AddAddress from '@/components/AddAddress.vue'
import { PlaceOrderReq } from "@/interface/res";
import { PlaceOrderApi,calculatePrice } from "@/network/modules";
import { getUsableList } from "@/network/modules/common";
@Component({
  components: {
    AddAddress
  },
  computed: {
    ...mapGetters(["addressList", "shoppingCartList"])
  }
})
export default class Order extends Vue {
  @Provide() id: Array<number> = []
  @Provide() totalPrice: number = 0
  @Provide() freight: number = 0
  @Provide() totalPriceGoods: number = 0

  @Provide() couponPrice: number = 0
  @Provide() price: number = 0
  @Provide() btnLoading: boolean = false

  @Provide() addressLists: Array<any> = []
  @Provide() isAddressMoreClick: boolean = false
  @Provide() isAddressMore: boolean = false
  @Provide() addressIndex: number = 0
  @Provide() isDeposit: number = 0
  @Provide() paymentPrice: number = 0
  
  @Provide() isDeposits: number = 0
  @Provide() addressType: number = 0
  @Provide() addressShow: boolean = false
  @Provide() addressData: AddAddressReq = {}
  @Provide() CouponList:any = [];
  @Provide() CouponListUse:any = [];
  @Provide() getCheckArry:any = [];
  @Provide() accountCouponIds:any = [];
  
  @Provide() infoList: Array<any> = []

  @Provide() billShow: boolean = false
  @Provide() billVerifyIndex: number = 0
  @Provide() billVerifyOrdinaryIndex: number = 1
  @Provide() billVerify: {
    invoiceTitle?: string,
    certificateNo?: string,
    registeredAddress?: string,
    bankAccount?: string,
    bankAccountNum?: string,
    registerPhone?: string,
    invoiceType?: number
  } = {}
  @Provide() billIndex: number = 0
  @Provide() ordinaryBillIndex: number = 1
  @Provide() ordinaryBill: { invoiceTitle: string } = {
    invoiceTitle: "",
  }
  @Provide() ordinaryBillFirm: { invoiceTitle: string, certificateNo: string } = {
    invoiceTitle: "",
    certificateNo: "",
  }
  @Provide() specialTicket: {
    invoiceTitle: string,
    certificateNo: string,
    registeredAddress: string,
    bankAccount: string,
    bankAccountNum: string,
    registerPhone: string,
  } = {
      invoiceTitle: "",
      certificateNo: "",
      registeredAddress: "",
      bankAccount: "",
      bankAccountNum: "",
      registerPhone: "",
    }
  @Provide() billMsg = {
    invoiceTitle: "",
    certificateNo: "",
    registeredAddress: "",
    bankAccount: "",
    bankAccountNum: "",
    registerPhone: "",
  }


  @Provide() couponShow: boolean = false
  @Provide() couponNum: number = 0
  @Provide() coupon: {
    name: string
  } = {
      name: ""
    }
  @Provide() remark: string = ''

  @Watch('id', { immediate: false, deep: false })
  @Watch('shoppingCartList', { immediate: false, deep: false })
  private idChange(): void {
    const _this: any = this;
    if (_this.$base.isNull(_this.shoppingCartList)) return
    let arr: Array<any> = []
    _this.id.map((item: string) => {
      const list = _this.shoppingCartList.filter((itemToo: any) => itemToo.cartId == item)
      arr = [...arr, ...list]
    })
    _this.infoList = arr
    console.log(_this.infoList)
    let totalPrice = 0
    let totalPriceGoods = 0;
    let newArry: any = [];
    let isDepositArry:any=[];

    _this.infoList.map((item: any) => {
      item.itemPrice = _this.$base.accMul(item.cartNum, _this.$base.toFixed(item.cartPriceOld))
      totalPrice = _this.$base.accAdd(item.itemPrice, totalPrice)
      totalPriceGoods = _this.$base.accAdd(item.cartPrice, totalPrice)
      console.log(item.cartPrice);
      newArry.push(_this.$base.toFixed(item.cartPrice) * item.cartNum)
      console.log(item.isDeposit)
      isDepositArry.push(item.isDeposit);
      // if (item.isDeposit == 1) {
      //   _this.isDeposits = 1;
      // } else {
      //   _this.isDeposits = 0;
      // }
    })
    console.log(isDepositArry)
    if(isDepositArry.includes(1)){
      _this.isDeposits = 1;
    }else{
      _this.isDeposits = 0;
    }
    console.log(_this.isDeposits)
    console.log(newArry)
    _this.totalPriceGoods = _this.sumOfArry(newArry);
    console.log(_this.totalPriceGoods)
    console.log('[ totalPriceGoods ] >', totalPriceGoods)
    _this.totalPrice = totalPrice;
    if (_this.isDeposits == 1) {
      _this.price = _this.totalPriceGoods
    } else {
      _this.price = _this.$base.subtr(_this.$base.accAdd(totalPrice, _this.freight), _this.couponPrice)
    }

  }

  @Watch('addressList', { immediate: true, deep: true })
  @Watch('isAddressMoreClick', { immediate: false, deep: true })
  private iListChange(): void {
    const _this: any = this;
    _this.addressLists = []

    if (_this.isAddressMoreClick) {
      _this.addressLists = [..._this.addressList]
      _this.isAddressMore = false
      return
    }

    if (_this.addressList.length < 4) {
      _this.addressLists = [..._this.addressList]
      _this.isAddressMore = false
      return;
    }

    _this.isAddressMore = true
    _this.addressList.map((item: any, index: number) => {
      if (index < 3) {
        _this.addressLists.push(item)
      }
    })

  }

  created() {
    const _this: any = this;
    if (!_this.$base.isNull(_this.$route.query.i)) {
      let str = _this.$decryptBy(_this.$route.query.i)
      if (str.indexOf(',') > -1) {
        _this.id = str.split(',')
      } else {
        _this.id.push(str)
      }

    }
  }

  mounted() {
    const _this: any = this;
    setTimeout(()=>{
      console.log(_this.isDeposits)  
    },1000)
    _this.GetCoupon(0)
  }
  changeCoupon(e:any){
    console.log(e);
    const _this: any = this;
    // isSuperpose==0?'可以叠加':'不可以叠加'
    let getCheck:any=[];
    let isSuperpose:any=[];
    // this.batchId=e.batchId;
    // optional 是否失效
    if(e.optional){
      // '不可以叠加'
      if(e.isSuperpose!=0){
        _this.CouponList.map((val:any,index:any)=>{
          if(val.batchId==e.batchId){
            val.check=!e.check;
          }else{
            val.check=false;
          }
        })
      }
      _this.CouponList.map((val:any,index:any)=>{
        if(val.check){
          getCheck.push(val);
          isSuperpose.push(val.isSuperpose);
        }
      })
      if(isSuperpose.includes(1)){
        _this.CouponList.map((val:any,index:any)=>{
          if(val.batchId!=e.batchId){
            val.check=false;
          }
        })
      }
      if(e.grantType==4||isSuperpose.includes(1)){
        _this.CouponList.map((val:any,index:any)=>{
          if(val.grantType==4&&val.accountCouponId!=e.accountCouponId){
            val.check=false
          }
        })
      }
      e.check=!e.check;
      console.log(_this.CouponList);
    }
  };
  async GetCoupon(e:number){
    const _this:any = this;
    _this.loading = true
    const res = await getUsableList({
      cartIds:_this.id.join(',')
    })
    let a = setTimeout(()=>{_this.loading = false;clearTimeout(a)},500)
    const { data, code, msg } = res
    if(code != 200 ){
      _this.$message.error(msg);
      return
    }
    console.log(data);
    _this.CouponListUse = data.filter((val: any,index: any)=>{
        return val.optional
    });
    _this.CouponList = data;
    _this.CouponList=_this.CouponList.map((val: any,index: any)=>{
      return {
        ...val,
        check:false
      }
    })
  console.log(_this.CouponList);
  }
  sureCoupon(){
    const _this:any = this;
    console.log(_this.CouponList);
    _this.accountCouponIds=[];
   
    _this.CouponList.map((val: any,index: any)=>{
      if(val.check){
       
        _this.accountCouponIds.push(val.accountCouponId)
      }
    })
    _this.couponShow = false;
    _this.getCalculatePrice();
    console.log(_this.getCheckArry);
    
    
  };
  sumOfArry(arry: any) {
    if (arry.length == 0) return
    return arry.reduce(function (pre: any, cur: any) {
      return pre + cur;
    });
  }

  /**
   * @Author HS
   * @Date 2021/7/30 10:12 上午
   * @Description: 打开地址弹窗
   * @Params: { number ： index - false } [下标：false-新增 true-下标]
   * @Return: null
  */
  addressShowFn(index: number) {
    const _this: any = this;
    if (index == undefined) {
      _this.addressType = 0
    }
    else {
      _this.addressType = 1
      _this.addressData = { ..._this.addressLists[index] }
    }

    _this.addressShow = true
  }

  /**
   * @Author HS
   * @Date 2021/8/3 4:07 下午
   * @Description:
   * @Params: { number ： index - true } [发票类型: 0-不需要 1-普通发票 2-企业专票]
   * @Params: { number ： index - false } [普通发票类型：1-个人 2-企业]
   * @Return: null
  */
  billTypeChange(index: number, indexToo: number) {
    const _this: any = this;
    _this.billIndex = index
    _this.billMsg.invoiceTitle =
      _this.billMsg.certificateNo =
      _this.billMsg.registeredAddress =
      _this.billMsg.bankAccount =
      _this.billMsg.bankAccountNum =
      _this.billMsg.registerPhone = ""
    if (_this.$base.isNull(indexToo)) return
    _this.ordinaryBillIndex = indexToo
  }

  /**
   * @Author HS
   * @Date 2021/8/3 7:50 下午
   * @Description: 确认发票
   * @Params: null
   * @Return: null
  */
  changeBillFn() {
    const _this: any = this;
    let reg = /^[0-9a-zA-Z\(\)\（\）\u4e00-\u9fa5]{0,50}$/;
    let Identification = /^[A-Z0-9]{15}$|^[A-Z0-9]{17}$|^[A-Z0-9]{18}$|^[A-Z0-9]{20}$/;
    switch (_this.billIndex) {
      case 0:
        _this.specialTicket.invoiceTitle =
          _this.specialTicket.certificateNo =
          _this.specialTicket.registeredAddress =
          _this.specialTicket.bankAccount =
          _this.specialTicket.bankAccountNum =
          _this.specialTicket.registerPhone = ""
        _this.ordinaryBillFirm.invoiceTitle =
          _this.ordinaryBillFirm.certificateNo = ''
        _this.ordinaryBill.invoiceTitle = ''
        break;
      case 1:
        switch (_this.ordinaryBillIndex) {
          case 1:
            if (_this.$base.isNull(_this.ordinaryBill.invoiceTitle)) {
              _this.billMsg.invoiceTitle = '请输入发票抬头'
              return
            }
            if (!reg.test(_this.ordinaryBill.invoiceTitle)) {
              _this.billMsg.invoiceTitle = '请输入正确的发票抬头'
              return
            }
            _this.billMsg.invoiceTitle = ''
            _this.billVerify = _this.ordinaryBill
            _this.billVerify.invoiceType = 0
            break;
          case 2:
            if (_this.$base.isNull(_this.ordinaryBillFirm.invoiceTitle)) {
              _this.billMsg.invoiceTitle = '请输入发票抬头'
              return
            }
            if (!reg.test(_this.ordinaryBillFirm.invoiceTitle)) {
              _this.billMsg.invoiceTitle = '请输入正确的发票抬头'
              return
            }
            _this.billMsg.invoiceTitle = ''

            if (_this.$base.isNull(_this.ordinaryBillFirm.certificateNo)) {
              _this.billMsg.certificateNo = '请输入购买方纳税人识别号或统一社会信用代码'
              return
            }
            // if (!Identification.test(_this.ordinaryBillFirm.certificateNo)) {
            //   _this.billMsg.certificateNo = '请输入正确的购买方纳税人识别号或统一社会信用代码'
            //   return
            // }
            _this.billMsg.certificateNo = ''

            _this.billVerify = _this.ordinaryBillFirm
            _this.billVerify.invoiceType = 1
            break
        }
        break;
      case 2:

        if (_this.$base.isNull(_this.specialTicket.invoiceTitle)) {
          _this.billMsg.invoiceTitle = '请输入单位名称'
          return
        }
        _this.billMsg.invoiceTitle = ''

        if (_this.$base.isNull(_this.specialTicket.certificateNo)) {
          _this.billMsg.certificateNo = '请输入购买方纳税人识别号或统一社会信用代码'
          return
        }
        // if (!Identification.test(_this.specialTicket.certificateNo)) {
        //   _this.billMsg.certificateNo = '请输入正确的购买方纳税人识别号或统一社会信用代码'
        //   return
        // }
        _this.billMsg.certificateNo = ''

        if (_this.$base.isNull(_this.specialTicket.registeredAddress)) {
          _this.billMsg.registeredAddress = '请输入单位《税务登记证》的地址'
          return
        }
        _this.billMsg.registeredAddress = ''

        if (_this.$base.isNull(_this.specialTicket.bankAccount)) {
          _this.billMsg.bankAccount = '请输入单位在税务局备案的银行名'
          return
        }
        _this.billMsg.bankAccount = ''

        if (_this.$base.isNull(_this.specialTicket.bankAccountNum)) {
          _this.billMsg.bankAccountNum = '请输入单位在税务局备案的银行账号'
          return
        }
        _this.billMsg.bankAccountNum = ''
        let tel = /^1[3|4|5|6|7|8|9]\d{9}$/;
        if (_this.$base.isNull(_this.specialTicket.registerPhone)) {
          _this.billMsg.registerPhone = '请输入单位在税务局备案的电话'
          return
        }
        _this.billMsg.registerPhone = ''

        _this.billVerify = _this.specialTicket
        _this.billVerify.invoiceType = 2
        break;
    }

    _this.billVerifyIndex = _this.billIndex
    _this.billVerifyOrdinaryIndex = _this.ordinaryBillIndex
    _this.billShow = false

  }
  async getCalculatePrice() {
    const _this: any = this;
    let params: PlaceOrderReq = {
      accountCouponIds:_this.accountCouponIds.toString(),
      cartIds: _this.id.join(','),
      addressId: _this.addressLists[_this.addressIndex].addressId,
      isDeposit: _this.isDeposits,
      ..._this.billVerify
    };
    console.log(params);
    const res = await calculatePrice(params)
    console.log(res);
    _this.paymentPrice=res.data.paymentPrice;
    if(_this.isDeposits==0){
      _this.price=res.data.paymentPrice;
    }else{
      _this.price=res.data.paymentDepositPrice;
    }
    let getNnm: any=[];
    _this.getCheckArry=res.data.cusAccountCouponList;
    _this.getCheckArry.map((val: any,index: any)=>{
      getNnm.push(val.deductPrice);
    })
    console.log(getNnm);
   
    const sum = (...arr: any) => [].concat(...arr).reduce((acc, val) => Number(acc) + Number(val), 0);
    _this.couponPrice=sum(getNnm)
    console.log(_this.couponPrice);
  }
  /**
   * @Author HS
   * @Date 2021/8/4 9:42 上午
   * @Description: 提交定单
   * @Params: null
   * @Return: null
  */
  async PlaceOrderFn() {
    const _this: any = this;

    _this.btnLoading = true

    let params: PlaceOrderReq = {
      accountCouponIds:_this.accountCouponIds.toString(),
      cartIds: _this.id.join(','),
      addressId: _this.addressLists[_this.addressIndex].addressId,
      isDeposit: _this.isDeposits,
      ..._this.billVerify
    };
    console.log(params);

    if (!_this.$base.isNull(_this.remark)) {
      params.remarks = _this.remark
    }
    console.log(params);
    let reg = /^[0-9a-zA-Z\(\)\（\）\u4e00-\u9fa5]{0,50}$/;
    let Identification = /^[A-Z0-9]{15}$|^[A-Z0-9]{17}$|^[A-Z0-9]{18}$|^[A-Z0-9]{20}$/;
    if (_this.billVerifyIndex != 0) {
      if (params.invoiceTitle != undefined && !reg.test(params.invoiceTitle)) {
        _this.$message.error('请输入正确的发票抬头')
        _this.btnLoading = false
        return
      }
      if (params.certificateNo != undefined && !Identification.test(params.certificateNo)) {
        _this.$message.error('请输入正确的购买方纳税人识别号或统一社会信用代码')
        _this.btnLoading = false
        return
      }
    }

    const res = await PlaceOrderApi(params)
    let a = setTimeout(() => { _this.btnLoading = false; clearTimeout(a) }, 500)
    const { data, code, msg } = res
    if (code != 200) {
      _this.$message.error(msg);
      return
    }

    let list = _this.shoppingCartList.filter((item: any) => { return _this.id.indexOf(item.cartId) === -1 })
    console.log('-------------------------C');
    _this.$store.commit('SET_LIST', JSON.stringify(list))
    const path = _this.$base.routeLinkSplice(_this.$IndexConfig.pay.path, 'i=' + data.orderId)
    // _this.$base.goRouter(path)
    window.close();
    window.open(path, "_blank");

  }


  /**
   * @Author HS
   * @Date 2021/7/7 2:07 下午
   * @Description: 页面跳转
   * @Params: null
   * @Return: null
   */
  goRoute() {
    const _this: any = this;
    if (_this.$base.isLogin()) {
      const path = _this.$base.routeLinkSplice(_this.$LoginConfig.login.path, 'r=diy&i=' + _this.detail.customizedUnitCode)
      _this.$base.goRouter(path)
      return
    }
    const path = _this.$base.routeLinkSplice(_this.$IndexConfig.diy.path, 'i=' + _this.detail.customizedUnitCode)
    _this.$base.goRouter(path)
  }

}
